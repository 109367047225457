import React, { memo } from 'react'
import { Trans } from 'react-i18next'
import './index.scss'

interface DanmakuItemProps {
  item: Raffle.Record
}

function DanmakuItem(props: DanmakuItemProps) {
  return <div className="cmp-danmaku-item">
    <img src={props.item.avatar} />
    <span>
      <Trans i18nKey="Raffle.RewardBean" values={{ num: props.item.number }}>
        <strong />
      </Trans>
    </span>
  </div>
}

export default memo(DanmakuItem)