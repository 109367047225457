import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef, useState } from 'react'
import clsx from "clsx"
import './index.scss'

interface DialogProps {
  children: any
  onClose?: () => void
}

interface RuleHandle {
  show: () => void
  hide: () => void
}

const stopEvent = (e: Event) => {
  e.preventDefault()
}

function Dialog(props: DialogProps, ref: any) {
  const refMask = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState(false)

  const handleShow = useCallback(() => {
    refMask.current?.addEventListener('mousewheel', stopEvent, { passive: false })
    refMask.current?.addEventListener('touchmove', stopEvent, { passive: false })
    setVisible(true)
  }, [])

  const handleHide = useCallback(() => {
    refMask.current?.removeEventListener('mousewheel', stopEvent)
    refMask.current?.removeEventListener('touchmove', stopEvent)
    setVisible(false)
    props.onClose && props.onClose()
  }, [])

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide
  }))
  return <div className={clsx("cmp-dialog", {
    hide: !visible
  })} onClick={handleHide}>
    <div ref={refMask} className='cmp-dialog__mask' />
    <div className='cmp-dialog__container'>
      {props.children}
    </div>
  </div>
}

export default memo(forwardRef<RuleHandle, DialogProps>(Dialog))