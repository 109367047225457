import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImgBean from '../../images/bean.webp'
import './index.scss'
import moment from 'moment'
import { getRecords } from '../../services'
import { toast } from '@/utils/bridge'

interface RecordProps {
  item: Raffle.Record
}

function Record(props: RecordProps) {
  const { t } = useTranslation()

  return <div className='cmp-record'>
    <img src={ImgBean} />
    <div className='cmp-record__info'>
      <span>{t('Raffle.Bean', { bean: props.item.number })}</span>
      <span>{moment(props.item.createTime).format('YYYY-MM-DD HH:mm:ss')}</span>
    </div>
  </div>
}

interface RecordsHandle {
  refresh: () => void
}

function Records(props: object, ref: any) {
  const { t } = useTranslation()
  const [records, setRecords] = useState<Raffle.Record[]>([])

  const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }, [])

  const handleGetRecords = useCallback(async () => {
    const res = await getRecords()
    if (!res) return
    if (res.code !== 200) {
      toast(res.message)
      return
    }

    setRecords(res.data)
  }, [])

  useImperativeHandle(ref, () => ({
    refresh: handleGetRecords
  }))

  useEffect(() => {
    handleGetRecords()
  }, [])

  return <div className="cmp-records" onClick={handleClick}>
    <div className='cmp-records__title'>
      {t('Raffle.Records')}
    </div>
    <div className='cmp-records__container'>
      {records.map((item, i) => <Record item={item} key={i} />)}
    </div>
  </div>
}

export default memo(forwardRef<RecordsHandle, object>(Records))