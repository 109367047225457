import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import './index.scss'

function Rule(props: object, ref: any) {
    const { t } = useTranslation()

    const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
    }, [])

    return <div className='cmp-rule' onClick={handleClick}>
        <div className='cmp-rule__title'>
            {t('Raffle.Rule')}
        </div>
        <div className='cmp-rule__description'>
            {new Array(4).fill(0).map((_, i) =>
                <span key={i}>{t(`Raffle.Rule${i + 1}`, { APP: process.env.REACT_APP_DISPLAY_NAME })}</span>
            )}
        </div>
    </div>
}

export default memo(Rule)