import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './index.scss'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import ImgHeaderBannerEn from './images/header-banner-en.webp'
import ImgHeaderBannerAr from './images/header-banner-ar.webp'
import ImgAvatarFrameThis from './images/avatar-frame-this.webp'
import ImgAvatarFrameLast from './images/avatar-frame-last.webp'

import TabButtons from '@/components/TabButtons';
import RewardsPool from './components/RewardsPool';
import Rewards from './components/Rewards';
import Rule from './components/Rule';
import Rank from './components/Rank';
import { getRank, getRewardsPool } from './services'
import { toast } from '@/utils/bridge'
import moment from 'moment'
import { useDesign } from '@/hooks'
import Loading from '@/components/Loading'
import { tabKey } from '../invite/components/Detail'

const { prefixCls } = useDesign('pg-host-honor')

function HostHonor() {
    const { t, i18n } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState<{
        selectedTab: number
        rewardsPool?: RoomSupport.RewardsPool
    }>({
        selectedTab: 1
    })
    const refState = useRef(state)
    refState.current = state

    const [rankList, setRankList] = useState<RoomSupport.RankItem[]>([])

    const tabsTitles = useMemo(() => {
        return ['HostHonor.ThisWeek', 'HostHonor.LastWeek', 'HostHonor.RewardRule']
    }, [])

    const seconds = useMemo(() => {
        return state.rewardsPool?.endTime ? Number(((state.rewardsPool.endTime - Date.now()) / 1000).toFixed(0)) : 0
    }, [state.rewardsPool?.endTime])

    const fetchGetRewardsPool = useCallback(async () => {
        const res = await getRewardsPool()
        if (!res) return
        if (res.code !== 200) {
            toast(res.message)
            return
        }

        setState({
            ...refState.current,
            rewardsPool: res.data || {},
        })
    }, [])

    const fetchRankList = useCallback(async (type: number) => {
        setLoading(true)
        const res = await getRank(type === 1 ? 0 : 1)
        setLoading(false)
        if (!res) return
        const { code, data, message } = res
        if (code !== 200) {
            toast(message)
            return
        }
        if (!data) return

        const rankList = data.rankList.map((item, i) => ({ ...item, index: i + 1 }))
        console.log(rankList)
        setRankList(rankList)
    }, [])

    const handleTabChange = useCallback((i: number) => {
        setState({
            ...refState.current,
            selectedTab: i
        })
    }, [])

    useEffect(() => {
        fetchGetRewardsPool()
    }, [])
    useEffect(() => {
        fetchRankList(state.selectedTab)
    }, [state.selectedTab])

    return (
        <div className={clsx(prefixCls)}>
            {loading && <Loading />}
            <header className={`${prefixCls}__header`}>
                <img
                    src={i18n.language === 'ar' ? ImgHeaderBannerAr : ImgHeaderBannerEn}
                />
                <div className={`${prefixCls}__header-phase`}>
                    {state.rewardsPool && <>
                        <span>{t("Common.Phase", { phase: state.rewardsPool.activityPeriod || 1 })}</span>
                        <span>{t('Common.EventTime', {
                            startTime: moment(state.rewardsPool.startTime).format('YYYY-MM-DD'),
                            endTime: moment(state.rewardsPool.endTime).format('YYYY-MM-DD')
                        })}</span>
                        <span>{t('Common.TimeTips', { number: state.rewardsPool.activityThresholdValue })}</span>
                    </>}
                </div>
                <div className={`${prefixCls}__header-host`}>
                    <div className={`${prefixCls}__header-host-img`}>
                        <img
                            className={`${prefixCls}__header-host-frame`}
                            src={state.selectedTab === 1 ? ImgAvatarFrameLast : ImgAvatarFrameThis}
                        />
                        <img
                            className={`${prefixCls}__header-host-avatar`}
                            src={rankList[0]?.avatar || ''}
                        />
                    </div>
                    <div className={`${prefixCls}__header-host-name`}>
                        {rankList[0]?.roomName || '--'}
                    </div>
                </div>
            </header>
            {/* <RewardsPool propRewardList={state.rewardsPool?.propRewardList || []} /> */}
            <TabButtons selectedTab={state.selectedTab} setSelectedTab={handleTabChange} tabText={tabsTitles}>
                {state.selectedTab === 0 &&
                    <div className={`${prefixCls}__container`}>
                        <span className={`${prefixCls}__container-title`}>{t('AgencyHonor.CurrentRank')}</span>
                        <Rank selectedTab={state.selectedTab} seconds={seconds} rankList={rankList} />
                    </div>
                }
                {state.selectedTab === 1 &&
                    <div className={`${prefixCls}__container`}>
                        <span className={`${prefixCls}__container-title`}>{t('AgencyHonor.LastWeekRank')}</span>
                        <Rank selectedTab={state.selectedTab} rankList={rankList} />
                    </div>
                }
                {state.selectedTab === 2 &&
                    <div >
                        <div className={clsx(`${prefixCls}__container`)}>
                            <span className={`${prefixCls}__container-title`}>{t('AgencyHonor.RankRewards')}</span>
                            <Rewards />
                        </div>
                        <div className={clsx(`${prefixCls}__container`)}>
                            <span className={`${prefixCls}__container-title`}>{t('Common.ActiveRule')}</span>
                            <Rule />
                        </div>
                    </div>
                }
            </TabButtons>
        </div >
    )
}
export default HostHonor