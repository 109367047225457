import { service } from '@/utils/net'

// 1:钻石流水 2:金豆流水 3:公会周榜 4:开麦周榜, 默认是1
type TActivityType = 1 | 2 | 3 | 4
const activityType: TActivityType = 4

// 奖池
export async function getDetail(): Promise<
  Response<Raffle.Detail>
> {
  return service({
    method: 'GET',
    params: {
      activityType
    },
    url: '/h5doings/draw/prize/getHome'
  }).then((res) => res?.data)
}

// 获取奖励配置
export async function drawPrize(): Promise<
  Response<Raffle.Prize>
> {
  return service({
    method: 'POST',
    url: '/h5doings/draw/prize/execute'
  }).then((res) => res?.data)
}

// 获取奖励配置
export async function getRecords(): Promise<
  Response<Raffle.Record[]>
> {
  return service({
    method: 'GET',
    url: '/h5doings/draw/prize/getRewardRecord'
  }).then((res) => res?.data)
}
