import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import clsx from 'clsx'

import ImgHeaderBannerEn from './images/background.webp'
import ImgHeaderBannerAr from './images/background-ar.webp'
import Rule from './components/Rule';
import { getDetail, drawPrize } from './services'
import { toast } from '@/utils/bridge'
import { useDesign } from '@/hooks'
import Loading from '@/components/Loading'
import DanmakuItem from './components/Dm'
import Danmaku from 'rc-danmaku'
import GameBox from './components/Game/Box'
import GameTask from './components/Game/Task'
import ImagePrize from './images/gift.webp'
import Dialog from './components/Dialog'
import Prize from './components/Prize'
import Records from './components/Records'
import './index.scss'

const { prefixCls } = useDesign('pg-raffle')

function Raffle() {
    const { t, i18n } = useTranslation()
    const [loading, setLoading] = useState(false)
    const danmakuInsRef = useRef<Danmaku | null>(null);
    const [detail, setDetail] = useState<Raffle.Detail>()
    const [prize, setPrize] = useState<Raffle.Prize>()
    const refDmIndex = useRef(0)
    const [gameBoxes, setGameBoxes] = useState([false, false, false])
    const [tasks, setTasks] = useState<Raffle.Task[]>([
        { number: 1, title: t('Raffle.Task1Title'), description: t('Raffle.Task1Description'), status: true },
        // { number: 99, title: t('Raffle.Task2Title'), description: t('Raffle.Task2Description'), status: true },
        // { number: 99, title: t('Raffle.Task3Title'), description: t('Raffle.Task3Description'), status: true },
    ])
    const refRuleDialog = useRef<React.ElementRef<typeof Dialog>>(null)
    const refPrizeDialog = useRef<React.ElementRef<typeof Dialog>>(null)
    const refRecordsDialog = useRef<React.ElementRef<typeof Dialog>>(null)
    const refRecords = useRef<React.ElementRef<typeof Records>>(null)

    useEffect(() => {
        if (!detail?.rewardRecordList.length) return

        const handler = setInterval(() => {
            const item = detail?.rewardRecordList[refDmIndex.current] || null
            if (!item) return

            danmakuInsRef.current?.push(<DanmakuItem item={item} />)
            refDmIndex.current = (refDmIndex.current + 1) % detail!.rewardRecordList.length
        }, 2000)

        return () => {
            clearInterval(handler)
        }
    }, [detail?.rewardRecordList])

    const handleGetRDetail = useCallback(async () => {
        setLoading(true)
        const res = await getDetail()
        setLoading(false)
        if (!res) return
        if (res.code !== 200) {
            toast(res.message)
            return
        }

        setDetail(res.data)
    }, [])

    const handleBoxOpen = useCallback(async (index: number) => {
        setGameBoxes([false, false, false].map((d, i) => i === index ? !d : d))
        const res = await drawPrize()
        if (!res) return
        if (res.code !== 200) {
            toast(res.message)
            return
        }

        setPrize(res.data)
        refPrizeDialog.current?.show()
    }, [])

    const handleShowRecords = useCallback(() => {
        refRecordsDialog.current?.show()
    }, [])

    const handleShowRules = useCallback(() => {
        refRuleDialog.current?.show()
    }, [])

    const onPrizeDialogClose = useCallback(() => {
        setGameBoxes([false, false, false])
        handleGetRDetail()
        refRecords.current?.refresh()
    }, [])

    const handleTaskGo = useCallback(() => { }, [])

    useEffect(() => {
        handleGetRDetail()
    }, [])

    useEffect(() => {
        const danmakuIns = new Danmaku('.dm-wrapper', {
            speed: 60
        })
        danmakuInsRef.current = danmakuIns
    }, [])

    return (
        <div className={clsx(prefixCls)}>
            {loading && <Loading />}
            <header className={`${prefixCls}__header`}>
                <img
                    src={i18n.language === 'ar' ? ImgHeaderBannerAr : ImgHeaderBannerEn}
                />
                <div className={`${prefixCls}__header-dm`}>
                    <div className='dm-wrapper' />
                </div>
                <div className={`${prefixCls}__header-records`} onClick={handleShowRecords}>
                    <span>{t('Raffle.Records')}</span>
                </div>
                <div className={`${prefixCls}__header-rule`} onClick={handleShowRules}>
                    <span>{t('Raffle.Rule')}</span>
                </div>
            </header>
            <div className={`${prefixCls}__game`}>
                {gameBoxes.map((status, i) => <GameBox
                    key={i}
                    status={status}
                    index={i}
                    onOpen={handleBoxOpen}
                />)}
                <div className={`${prefixCls}__game-remain`}>
                    <img src={ImagePrize} className={`${prefixCls}__game-prize`} />
                    <Trans i18nKey="Raffle.RemainingKey" values={{ num: detail ? detail.prizeNum || '0' : '-' }}>
                        <strong />
                    </Trans>
                </div>
            </div>
            {tasks.map((task, i) => <GameTask item={task} onGo={handleTaskGo} key={i} />)}
            <Dialog ref={refRuleDialog}>
                <Rule />
            </Dialog>
            <Dialog ref={refPrizeDialog} onClose={onPrizeDialogClose}>
                <Prize item={prize} onClose={() => refPrizeDialog.current?.hide()} />
            </Dialog>
            <Dialog ref={refRecordsDialog}>
                <Records ref={refRecords} />
            </Dialog>
            {/* <Rank /> */}
        </div >
    )
}
export default Raffle