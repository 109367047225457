import ImageBox from './images/box.webp'
import ImageBoxOpened from './images/box-opened.webp'
import ImageText from './images/open.webp'
import ImageTextOpened from './images/opened.webp'
import React, { memo } from 'react'
import './index.scss'

interface GameBoxProps {
  index: number
  status: boolean
  onOpen: (index: number) => void
}

function GameBox(props: GameBoxProps) {
  return <div className="cmp-game-box">
    <img className="cmp-game-box__image" src={props.status ? ImageBoxOpened : ImageBox} />
    {props.status
      ? <img className='cmp-game-box__text' src={ImageTextOpened} />
      : <img className='cmp-game-box__text' src={ImageText} onClick={() => props.onOpen(props.index)} />}
  </div>
}

export default memo(GameBox)