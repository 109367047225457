import React, { memo } from "react"
import ImageGift from '@Raffle/images/gift.webp'
import ImageGo from './images/go.webp'
import ImageGot from './images/got.webp'
import './index.scss'

interface GameTaskProps {
  item: Raffle.Task
  onGo: () => void
}

function GameTask(props: GameTaskProps) {
  return <div className="cmp-game-task">
    <div className="cmp-game-task__left">
      <div className="cmp-game-task__left-box">
        <img src={ImageGift} />
      </div>
      <span className="cmp-game-task__left-count">x{props.item.number}</span>
    </div>
    <div className="cmp-game-task__middle">
      <span className="cmp-game-task__middle-title">{props.item.title}</span>
      <span className="cmp-game-task__middle-description">{props.item.description}</span>
    </div>
    <div className="cmp-game-task__right">
      {props.item.status ? <img src={ImageGot} /> : <img src={ImageGo} onClick={props.onGo} />}
    </div>
  </div>
}

export default memo(GameTask)