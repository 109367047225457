import React, { memo, useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import ImgBean from '../../images/bean.webp'
import ImgGet from './images/get.webp'
import './index.scss'

interface PrizeProps {
  item?: Raffle.Prize
  onClose: () => void
}

function Prize(props: PrizeProps) {
  const { t } = useTranslation()
  const [prize, setPrize] = useState<Raffle.Prize>()

  useEffect(() => {
    setPrize(props.item)
  }, [props.item])

  return <div className="cmp-prize">
    <div className="cmp-prize__title">
      <span>{t('Raffle.Congratulations')}</span>
    </div>
    <img className="cmp-prize__bean" src={ImgBean} />
    <span className="cmp-prize__num">{t('Raffle.Bean', { bean: prize?.number || '0' })}</span>
    <img className="cmp-prize__get" src={ImgGet} onClick={props.onClose} />
  </div>
}

export default memo(Prize)